
html,
body {
  padding: 0;
  margin: 0;
  text-align: unset !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    height: 100%;

}
html{
  min-height: 200px;
}
a {
  color: inherit;
  text-decoration: none;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Cairo' !important;
  /* font-size: 100%; */
  /* height: 100%; */
  
}

@font-face{
  font-family: 'Cairo';
 font-display: swap;
  src: local('Cairo'), local('Cairo'), url(/fonts/cairo/Cairo-Regular.ttf) format('opentype');
}
@media (prefers-color-scheme: dark) {
  /* html {
    color-scheme: dark;
  } */
  body {
    color: white;
    background: black;
    text-align: unset !important;
  }
}
/* .shakeAni:hover{
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) ;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake{

  0%{
transform: rotate(0deg);
  }
  25%{
    transform: rotate(5deg);
  }
  50%{
    transform: rotate(0deg);
  }
  70%{
    transform: rotate(-5deg);
  }
   100%{
    transform: rotate(0deg);
  }
}
.Banner:hover{
  animation: jeza3Shake 0.82s cubic-bezier(.36, .07, .19, .97) infinite;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes jeza3Shake{
  10% , 90% {
  transform: translate3d(-1px ,0 , 0);
  }
  20% , 80% {
    transform: translate3d(2px ,0 , 0);
  }
  30% , 50% , 70% {
    transform: translate3d(-4px ,0 , 0);
  }
  40% , 60% {
    transform: translate3d(4px ,0 , 0);
  }
} */
.Circle{
  border-radius: 50%;
  background-color: #e5e5e5;
  padding: 1px;
  width: 0.8rem;
  height: 0.8rem;
  margin: auto;
}
.contentNavWidth{
  width: 100%;
}

.test {
  border: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.rtl-btn{
  border:0 !important;
  background-color: #fff !important;
  color:#F52A59  !important;
  box-shadow: 0 0 5px #bcbcbc;
}


.rtl-btn > svg{
  stroke:#F52A59  !important;
  
}
.rtl-btn:disabled > svg{
  opacity: 0.5;
  stroke:#F52A59  !important
  
}

.ltr-btn{
  border:0 !important;
  background-color: #fff !important;
  color:#F52A59  !important;
  box-shadow: 0 0 5px #bcbcbc;
}


.ltr-btn > svg{
  stroke:#F52A59  !important;

}
.ltr-btn:disabled > svg{
  opacity: 0.5;
  stroke:#F52A59  !important;

  
}
/* ---------------------------------- categoryMenu----------------------------------------- */
.rtl-btn-Cat{
  border:0 !important;
  background-color: #fff !important;
  color:#F52A59  !important;
  /* box-shadow: 0 0 5px #bcbcbc; */
}


.rtl-btn-Cat > svg{
  stroke:#F52A59  !important;
  
}
.rtl-btn-Cat:disabled > svg{
  opacity: 0;
  stroke:#F52A59  !important
  
}

.ltr-btn-Cat{
  border:0 !important;
  background-color: #fff !important;
  color:#F52A59  !important;
  /* box-shadow: 0 0 5px #bcbcbc; */
}


.ltr-btn-Cat > svg{
  stroke:#F52A59  !important;

}
.ltr-btn-Cat:disabled > svg{
  opacity: 0;
  stroke:#F52A59  !important;

  
}
.Category .rts___tab___selected{
  color: #008C8D  !important;
  border: 0px!important;
  box-shadow: none !important;
}
.Category .rts___tab___selected a{
  color: #008C8D  !important;
  border: 0px!important;
}
.Category .rts___tab{
  font-weight:600 !important;
  font-size: 0.8rem;
  color: black ;
  padding: 2px 7px;
  border: 0px;

}
.Category{
  padding: 0 !important;
}
/* ---------------------------------- end categoryMenu----------------------------------------- */
.rts___tab{

  border-radius: 12px !important;

}
.rts___tab___selected{
  background-color: white !important;
  color: #008C8D  !important;
  font-weight: 600;
  border: 2px solid #ccf0ef !important;
  border-radius: 12px !important;
  font-size: 1em;

} 

.griditem{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ScrollContent{
  transform:translate3d(0,0,0);
}

.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.myswiperMarket .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.cursor-pointer1{
 
   position: absolute;
    cursor: pointer;
   top:41%;
  /* padding-left:8% ; */
   left: 9px;
   font-size: 45px;
   color:  rebeccapurple;
   z-index: 1;
/*    width:60px;
  */
}
.cursor-pointer1-product{
  position: absolute;
  cursor: pointer;
 top:40%;
/* padding-left:8% ; */
 left: 0px;
 font-size: 45px;
 color:  rebeccapurple;
 z-index: 1;
 width:60px;
}

[dir="rtl"].cursor-pointer1-product{
 
  position: absolute;
   cursor: pointer;
  top:27%;
 /* padding-left:8% ; */
  /* right:100px; */
  font-size: 45px;
  color:  rebeccapurple;
  z-index: 1;
  left: 0;
  width:60px;

}
[dir="rtl"].cursor-pointer1{
 
  position: absolute;
   cursor: pointer;
  top:27%;
 /* padding-left:8% ; */
  /* right:100px; */
  font-size: 45px;
  color:  rebeccapurple;
  z-index: 1;
  left: 0;
/*   width:60px; */

}
.swiper-button {
  display: flex;
  position: absolute;
  top: calc(50% - 12px);
  z-index: 10;
  cursor: pointer;
}
.cursor-pointer1-artical{
  position: absolute;
  cursor: pointer;
 /* top:41%; */

 left: 11%;
 font-size: 35px;
 color:  rebeccapurple;
 z-index: 1;
}
.arrow-services-right{
  position: absolute;
  cursor: pointer;
 left: 2%;

 color:  rebeccapurple;
 z-index: 1;
}
.cursor-AddCard-1{
  position: absolute;
  cursor: pointer;
 top:41%;

 left: 6%;
 font-size: 45px;
 color:  rebeccapurple;
 z-index: 1;
}
.cursor-pointer1-products{
  position: absolute;
  cursor: pointer;
 top:30%;
padding-left:8% ;
 left: -3%;
 font-size: 45px;
 color:  rebeccapurple;
 z-index: 1;
}
.cursor-pointer-Scrollright{
  position: absolute;
  cursor: pointer;
 top:18%;
padding-left:8% ;
 left: -34%;
 font-size: 45px;
 color:  rebeccapurple;
 z-index: 1;
}
.cursor-pointer2{
  position: absolute;
  cursor: pointer;
 top:42%;

 right: 9px;
 font-size: 45px;
 color:  rebeccapurple;
 z-index: 1;
}
.cursor-pointer2-artical{
  position: absolute;
  cursor: pointer;
 /* top:41%; */

 right: 11%;
 font-size: 35px;

 z-index: 1;
}
.arrow-services-left{
  position: absolute;
  cursor: pointer;
 /* top:41%; */

 right: 2%;


 z-index: 1;
}
.cursor-AddCard-2{
  position: absolute;
  cursor: pointer;
 top:41%;

 right: 6%;
 font-size: 45px;

 z-index: 1;
}
.cursor-pointer2-products{
  position: absolute;
  cursor: pointer;
 top:30%;

 right: 5%;
 font-size: 45px;

 z-index: 1;
}
.cursor-pointer-Scrollleft{
  position: absolute;
  cursor: pointer;
 top:21%;

 right: 5%;
 font-size: 45px;

 z-index: 1;
}
.swiper{
  width: 100%;

}
/* @supports(display: flex){ */
.swiper-silde{
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}
/* } */
.swiper-slide img{
  width: 100%;
  height: 100%;
object-fit: contain;
 
  display: block;

 
}
.Sopnsor > .swiper-slide img{
  width: 700px !important;
  height: 500px !important;
object-fit: contain;
 
  display: block;

 
}

.svglottie{
  width: calc(max(500px,40vm)) !important;
}
#imgSuccess{
  width: calc(max(500px,40vm)) !important;
}
.Swipertradmarks{
  height: 0 !important;
}








.MuiAvatar-img{
  object-fit: contain !important ;
}
 .swiper-pagination{
   position: initial !important; 
  margin-top: 21px;
  text-align: center;
}
.Sopnsor > .swiper-pagination {

  position: absolute !important;
  top: 85%;


}

.Table > .swiper-pagination {

  position: absolute !important;
  top:78% !important;


}
.Table > .swiper-wrapper {

 /* height: 151px; */

 height: 165px;


}
.swiper-vertical > .swiper-wrapper{
  gap: 0.5rem;
}
.rts___tabs___container {
  position: relative;
}

.rts___nav___btn {
  border: none;
  width: 100%;
  position: absolute;

  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(0, -50%);
  background-color: var(--rts-primary-color);
  border-radius: 50%;
}
.rts___nav___btn > svg {
  stroke: #fff;
}
.rts___right___nav___btn {
  right: -20px;
}
.rts___left___nav___btn {
  left: -20px;
}

 .active{
  color: #008C8D  !important;
}
.bgactive{
background-color: #efefef63;
}
 .active >button{
  color: #008C8D  !important;
}

.swiper-pagination-clickable > .swiper-pagination-bullet{

  background-color: #F52A59 !important;
 
}
.language{
  font-size: 1.1rem !important;

  color:#fff !important; 
  font-weight: 600 !important;
}

.animation1{   

animation: navLinkFade;
animation-duration: 0.7s;
animation-delay:0.1s ;
animation-timing-function: ease; 
animation-fill-mode: forwards;



}
[dir="ltr"] .animation1{   

  animation: navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:0.1s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
  
  
  }
.animation2{ 

animation: navLinkFade;
animation-duration:0.7s;
animation-delay:0.3s;
animation-timing-function: ease; 
animation-fill-mode: forwards;

}
[dir="ltr"] .animation2{ 

  animation: navLinkFadeleft;
  animation-duration:0.7s;
  animation-delay:0.3s;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
  }
.animation3{   

animation:  navLinkFade;
animation-duration: 0.7s;
animation-delay:0.5s ;
animation-timing-function: ease; 
animation-fill-mode: forwards;

}
[dir="ltr"] .animation3{   

  animation:  navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:0.5s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
  }
.animation4 {   
  animation:navLinkFade;
  animation-duration: 0.7s;
  animation-delay:0.7s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;

}
[dir="ltr"] .animation4 {   
  animation:navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:0.7s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;

}
.animation5{   

animation:navLinkFade;
animation-duration: 0.7s;
animation-delay:0.9s ;
animation-timing-function: ease; 
animation-fill-mode: forwards;

 
}
[dir="ltr"] .animation5{   

  animation:navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:0.9s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
   
  }
.animation6{   

animation:navLinkFade;
animation-duration: 0.7s;
animation-delay:1.2s ;
animation-timing-function: ease; 
animation-fill-mode: forwards;

 
}
[dir="ltr"] .animation6{   

  animation:navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:1.2s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
   
  }
.animation7{   

  animation:navLinkFade;
  animation-duration: 0.7s;
  animation-delay:1.2s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;

 
}
[dir="ltr"] .animation7{   
  animation:navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:1.2s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
   
  }
.animation8{   

animation:navLinkFade;
animation-duration: 0.7s;
animation-delay:1.5s ;
animation-timing-function: ease; 
animation-fill-mode: forwards;

 
}
[dir="ltr"] .animation8{   

  animation:navLinkFadeleft;
  animation-duration: 0.7s;
  animation-delay:1.5s ;
  animation-timing-function: ease; 
  animation-fill-mode: forwards;
  
   
  }
  
  .muiltr-w2vhhu-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 1.2rem !important;
  }
  .navcolor{
    background-color: #F52A59;

    box-shadow: 0 0 10px grey !important;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
  }
  .rightnav{
    float: right;
    display: flex;
   width: 100%;
   align-items: initial;
   justify-content: space-evenly;
   flex-direction: column;
}
 [dir="ltr"] .rightnav{
    float: right;
    display: flex;
   width: 100%;
   align-items: initial;
   justify-content: space-evenly;
   flex-direction: column;
}
.leftnav{
    float: left;
    display: flex;
   width: 100%;
   align-items: initial;

   flex-direction: column;
   gap: 1rem;
}
[dir="ltr"]  .leftnav{
    float: left;
    display: flex;
   width: 100%;
   align-items: initial;

   flex-direction: column;
}
.ButtonServices{
 
  cursor:pointer !important;
  
}
.nav{
  display: flex;
  align-items: center;
  justify-content: flex-start;
 max-width: 1294px;
 margin: auto;

 padding: 10px  0px !important;
  width: 100%; 

  flex-wrap: nowrap !important;
  width: 100%;

}
.navLinks{
  display: flex;
  justify-content: space-around;
  width: 50%;
  text-transform: uppercase;
 
  flex-direction: row;
}
.navLinks a , .navLinks .examination , .navLinks .Jeza3 {
  display: block;
  text-decoration: none;
  color:#fff;
  padding-left:20px ;
  padding-right: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 20px;

  transition: 0.5s ease;
  transform: translateX(0%);

}
.navLinks span .ButtonServices  {

  display: flex;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color:#fff;
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 20px !important;
  transition: 0.5s ease;
  transform: translateX(0%);
  padding-left:20px ;
  padding-right: 20px;
  padding-top: 0px !important ;
  
}
.navLinks .langauage span  {
  display: block;
  text-decoration: none;
  color:#fff;
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 0px !important;
  transition: 0.5s ease;
  transform: translateX(0%);
}
.paddingLR20{
  padding-left: 20px;
  padding-right:20px ;
}

.navLinks a:hover , a:active{
  color: #008C8D ;

}
.navLinks a:hover::after{
  content: "";
  width:30%;
  height: 2px;

}

.navLinksDesktop{

  display: flex;
   justify-content: space-between; 
flex: 0 78rem;
  text-transform: uppercase;
  padding: 10px 7px;
  margin: 0;
}
.navLinksDesktop a , span .ButtonServices {
display: block;
text-decoration: none;
/* color:#F52A59;  */
color:#fff; 
font-size: 0.8rem;
font-weight: 600;


  transition: 0.5s ease;
  transform: translateX(0%);
}

.ButtonServices >  ul li a{
  opacity: unset;
}
.navLinksDesktop a:hover  , span .ButtonServices:hover ,  .navLinksDesktop a:active{
  color: #008C8D ;
text-decoration: none;
outline: 0;



}
.HoverCat .ButtonCat:hover{
  color: #008C8D  !important;
  text-decoration: none;
  outline: 0;
  background-color: white ;

  border-radius: 0px;
  box-shadow: 0px -2px 4px 0px #bcbcbc;
}
.specialHover:hover{
  color: #008C8D  !important;
  text-decoration: none;
  outline: 0;
  background-color: white ;

  border-radius: 0px;
  box-shadow: 0px -2px 4px 0px #bcbcbc;
}
.specialHoverLink {
  color: #008C8D  !important;
  text-decoration: none;
  outline: 0;
  background-color: white ;

  border-radius: 0px;
  box-shadow: 0px -2px 4px 0px #bcbcbc;
}
.HoverCat .ButtonCat{

  width:max-content;
  height: 50px;
  border-radius: 0px;
  padding: 10px 10px !important;
 
}
.HoverCat .Allcat{

  border-radius: 0px;
  box-shadow: -2px -2px 4px -2px #bcbcbc;

}
[dir="ltr"] .HoverCat .Allcat{
  box-shadow: 2px -2px 4px -2px #bcbcbc;
}
.HoverCat .Allcat:hover{
  background-color: #ecedf1 !important;
}
.bgclick{
  background-color:white !important;
  width:100%;
}

.bghover{
  color: #008C8D  !important;
  background-color: white !important;
}
.bghover:hover{
  color: #008C8D  !important;
  background-color: white !important;
}
.bghoversub{
  color: #008C8D  !important ;
  background-color: #F9FBFF !important;

}
.nothing{
  color: black !important;
  background-color: transparent !important;
  
}
.navLinksDesktop a:hover::after,.navLinksDesktop  span .ButtonServices::after{
  content:" ";
  width:30%;
  height: 2px;
  outline: 0;
  text-decoration: none;
  outline: 0;

}
.menu{
  display: none; 
  position: relative;
  z-index: 4;
}
.menu div{
  width: 20px;
  height: 2px;
  background-color: #fff;
  transition:all 0.5s ease;
  margin: 5px auto 0px auto;
  flex: 0 0 25%;
}



.navActive{
  transform: translateX(0);
}

.navActive{
  transform: translateX(0);
}

.toggle.menuLine1{
  transform: rotate(-45deg) translate(-5px,5px);
  }
  .toggle.menuLine2{
      opacity: 0;
  }
  .toggle.menuLine3{
      transform: rotate(45deg)translate(-5px,-5px);
  } 

  @keyframes navLinkFade{
    0%{
        opacity: 0;
       transform: translate(-50px) ;  
    }
  
   
    100%{
        opacity: 1;
        transform: translateX(0);
    }
    }
    
    
    @keyframes navLinkFadeleft{
      0%{
        opacity: 0;
       transform: translate(50px) ;  
    }
  
   
    100%{
        opacity: 1;
        transform: translateX(0);
       
    }
    } 
   



.myswiper2 .swipler-slide img{
 
  object-fit: cover !important;
}
.galleryTitle > .swiper-silde img{
  object-fit: cover !important;
}
.productswiper > .swiper-slide img{
  object-fit: contain !important;
}
.test{
  box-shadow: 0  !important;
}
.myswiperMarket  .swiper-wrapper{
  box-sizing: border-box !important;
}
.myswiperArtical  .swiper-wrapper{
  margin-right: 10px !important;
  margin-left: 0px !important;
}
[dir="ltr"] .myswiperArtical .swiper-wrapper{
  margin-right: 0px !important;
  margin-left: 10px !important;
}
.myswiper9 .swiper-wrapper {
  margin-right: 10px !important;
  margin-left: 0px !important;
}
[dir="ltr"] .myswiper9 .swiper-wrapper{
  margin-right: 0px !important;
  margin-left: 10px !important;
}

@media only screen and  (min-width: 280px) {
  .HoverCat .Allcat{
    width: 272px ;
    height: 50px;
  }
  .nav{
    grid-template-columns: 34% 50% 15% !important;
  }
  .language{
    font-size: 0.9rem !important;
  }

.containerSe{
  float:none;

}
[dir="rtl"] .navLinks{
position: absolute;
z-index: 2;
top: 7vh;
left: 100%;
min-height: 92vh;
background: #fff;
display: flex;
flex-direction: column;
width: 100%;
margin: 0;
padding-top: 100px;
transform: translateX(-100%);
transition: all 0.5s ease-in;
align-items: center;

justify-content:flex-start;
}
[dir="ltr"] .navLinks{
position: absolute;
z-index: 2;
top: 7vh;
left: 100%;
min-height: 92vh;
background: #fff;
display: flex;
flex-direction: column;
width: 100%;
margin: 0;
padding-top: 50px;
transform: translateX(-100%);
transition: all 0.5s ease-in;
align-items: center;

justify-content:flex-start;


}
.menu{
display: block;
}
.navLinks a.animation4,.navLinks span.animation3,.navLinks a.animation1,.navLinks span.animation2,.navLinks a.animation5,.navLinks span.animation6 ,.navLinks a.animation7 , .navLinks span.animation8{
opacity: 0;
}


.navLinks a:hover , .active{
color: #008C8D ;
/*    letter-spacing: 3px; */
}
.navLinks a:hover::after{
content: "";
width:30%;
height: 2px;

}
}
@media (min-width:320px){
  .HoverCat .Allcat{
    width: 339px !important;
    height: 50px;
  }
}
@media (min-width:390px){
  .HoverCat .Allcat{
    width: 384px !important;
    height: 50px;
  }
}
@media (max-width:490px){
  .swiper-vertical > .swiper-wrapper{
    gap: 0.5rem;
    flex-direction: row;
  }
}
@media only screen and  (min-width: 540px) {
  .HoverCat .Allcat{
    width: 520px !important;
    height: 50px;
  }
  .Category .rts___tab{
    padding: 2px 7px;
  }
  .language{
    font-size: 1.1rem !important;
    /* color: #F52A59 !important; */
    color:#fff !important; 
    font-weight: 600 !important;
  }
}
@media (max-width:580px){

  .cursor-pointer1-product{
    position: absolute;
    cursor: pointer;
   top:24%;
  /* padding-left:8% ; */
   left: 0px;
   font-size: 45px;
   color:  rebeccapurple;
   z-index: 1;
   width:60px;
  }


  
  .cursor-pointer1-artical{
    position: absolute;
    cursor: pointer;
    left:4%;
    /*top:30%; */
    z-index: 1;
    font-size: 45px;
  }
  .arrow-services-right{
    position: absolute;
    cursor: pointer;
    left:1%;
    /*top:30%; */
    z-index: 1;
  }
  .cursor-AddCard-1{
    position: absolute;
    cursor: pointer;
   left:6%;
    top:30%;
    z-index: 1;
  }
  .cursor-pointer1-products{
    position: absolute;
    cursor: pointer;
   left:-3%;
    top:30%;
    z-index: 1;
  }
  .cursor-pointer-Scrollright{
    position: absolute;
    cursor: pointer;
   left:-3%;
    top:20%;
    z-index: 1;
  }

  .cursor-pointer2-artical{
    position: absolute;
  cursor: pointer;
  right: 4%;
    /* top:30%; */
    z-index: 1;
    font-size: 45px;
  }
  .arrow-services-left{
    position: absolute;
  cursor: pointer;
  right: 1%;
   
    z-index: 1;
  }
  .cursor-AddCard-2{
    position: absolute;
    cursor: pointer;
    right: 6%;
    top:30%;
    z-index: 1;
  }
  .cursor-pointer2-products{
    position: absolute;
  cursor: pointer;
  right: 6%;
    top:30%;
    z-index: 1;
  }
  .cursor-pointer-Scrollleft{
    position: absolute;
  cursor: pointer;
  right: 6%;
    top:20%;
    z-index: 1;
  }

} 
 @media only screen and  (max-width: 699px) {
    .nav{
      display: grid !important;
      grid-template-columns: 36% 56% 8%;
      /* grid-template-columns: 40% 44% 15%; */
     /*  justify-content: space-between; */
       padding: 0 5vw;
   }
    .containerSe{
        float:none;
   
    }
    [dir="rtl"] .navLinks{
          position: absolute;
          z-index: 2;
          top: 68px;
          left: 100%;
          min-height: 92vh;
          background: #F52A59;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          padding-top: 50px;
          transform: translateX(-100%);
          transition: all 0.5s ease-in;
          align-items: center;
          justify-content:flex-start;
          }
          [dir="ltr"] .navLinks{
            position: absolute;
            z-index: 2;
            top: 68px;
            left: 100%;
            min-height: 92vh;
            background: #F52A59;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
            padding-top: 50px;
            transform: translateX(-100%);
            transition: all 0.5s ease-in;
            align-items: center;
   
            justify-content:flex-start;
          
            }
        
          .menu{
            display: block;
          }
          .navLinks a:hover , a:active{
            color: #008C8D ;
       /*    letter-spacing: 3px; */
          }
          .navLinks a:hover::after{
            content: "";
            width:30%;
            height: 2px;
          
          }
          .navLinks a.animation4,.navLinks span.animation3,.navLinks a.animation1,.navLinks span.animation2,.navLinks a.animation5,.navLinks span.animation6 ,.navLinks a.animation7  , .navLinks span.animation8{
            opacity: 0;
          }

       
  
} 
@media only screen and (min-width: 580px) {
  .nav{
    grid-template-columns: 35% 58% 6% !important;
}

  .containerSe{
      float:none;
 
  }

      [dir="rtl"] .navLinks{
          position: absolute;
         z-index: 2;
          top: 7vh;
        left: 100%;
          min-height: 92vh;
          background: #fff;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          padding-top: 100px;
          transform: translateX(-100%);
          transition: all 0.5s ease-in;
          align-items: center;
        
          justify-content:flex-start;
          }
        [dir="ltr"] .navLinks{
          position: absolute;
         z-index: 2;
          top: 7vh;
          left: 100%;
          min-height: 92vh;
          background: #fff;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          padding-top: 50px;
          transform: translateX(-100%);
          transition: all 0.5s ease-in;
          align-items: center;
       
          justify-content:flex-start;
    
        
          }
        .menu{
          display: block;
        }
        .navLinks a.animation4,.navLinks span.animation3,.navLinks a.animation1,.navLinks span.animation2,.navLinks a.animation5,.navLinks span.animation6 ,.navLinks a.animation7 , .navLinks span.animation8{
          opacity: 0;
        }
      
     
      .navLinks a:hover , .active{
        color: #008C8D ;

      }
      .navLinks a:hover::after{
        content: "";
        width:30%;
        height: 2px;
      
      }
} 
@media only screen and  (min-width: 700px) and (max-width:991px) {
  .HoverCat .ButtonCat{

    padding: 10px 10px !important;

  }
  .HoverCat .Allcat{
    width: 190px !important;
    height: 50px ;

  }
  .Category .rts___tab{
    padding: 2px 3px;
  }
  .language{
    font-size: 1rem !important;

    color:#fff !important; 
    font-weight: 600 !important;
  }
  .nav{
    display: grid !important;
    grid-template-columns: 50% 43% 7%;
 
     padding: 0 5vw;
 }
  .containerSe{
      float:none;
 
  }
  [dir="rtl"] .navLinks{
        position: absolute;
       z-index: 2;
        top: 76px;
      left: 100%;
        min-height: 92vh;
        background: #F52A59;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding-top: 100px;
        transform: translateX(-100%);
        transition: all 0.5s ease-in;
        align-items: center;
      
        justify-content:flex-start;
        }
        [dir="ltr"] .navLinks{
          position: absolute;
         z-index: 2;
          top: 76px;
         left: 100%;
          min-height: 92vh;
    
          background: #F52A59;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          padding-top: 50px;
          transform: translateX(-100%);
          transition: all 0.5s ease-in;
          align-items: center;
 
          justify-content:flex-start;
        
          }
      
        .menu{
          display: block;
        }
        .navLinks a:hover , a:active{
          color: #008C8D ;
     /*    letter-spacing: 3px; */
        }
        .navLinks a:hover::after{
          content: "";
          width:30%;
          height: 2px;
        
        }
        .navLinks a.animation4,.navLinks span.animation3,.navLinks a.animation1,.navLinks span.animation2,.navLinks a.animation5,.navLinks span.animation6 ,.navLinks a.animation7  , .navLinks span.animation8{
          opacity: 0;
        }

     

}
@media (min-width:768px) and (max-width:912px){
  .cursor-pointer1-artical{
    position: absolute;
    cursor: pointer;
   /* top:41%; */
  
   left: 2% !important;
   font-size: 35px;
   color:  rebeccapurple;
   z-index: 1;
  }
  .cursor-pointer2-artical{
    position: absolute;
    cursor: pointer;

  
   right: 2% !important;
   font-size: 35px;
  
   z-index: 1;
  }


  .Sopnsor > .swiper-slide img{
    width: 700px !important;
    height: 500px !important;
  object-fit: contain;
   
    display: block;
  
   
  }

  .arrow-services-right{
    position: absolute;
    cursor: pointer;
    left:-4%;
  
    z-index: 1;
  }

  .slider-container .swiper {
    height: 400px;
  }  

.slider-container .swiper-slide{
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.slider-container .swiper-slide svg{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
 

  .arrow-services-left{
    position: absolute;
  cursor: pointer;
  
 
    /* top:20%; */
    z-index: 1;
  }



  .swiper{
    width: 100%;
    /* height: 100%; */
  height: 100% 1;
  }

}
@media (min-width:913px){
  .Sopnsor > .swiper-slide img{
    width: 700px !important;
    height: 500px !important;
  object-fit: contain;
   
    display: block;
  
   
  }
  .cursor-pointer1{
 
    position: absolute;
     cursor: pointer;
    top:42%;

    left: 0px;
    font-size: 45px;
    color:  rebeccapurple;
    z-index: 1;
    width:100px;
  
  }
 [dir="ltr"] .cursor-pointer1{
 
    position: absolute;
     cursor: pointer;
    top:42%;

    left: 42px;
    font-size: 45px;
    color:  rebeccapurple;
    z-index: 1;
    width:100px;
  
  }
  .cursor-pointer2{
    position: absolute;
    cursor: pointer;
   top:42%;
  
   right: 37px;
   font-size: 45px;
   color:  rebeccapurple;
   z-index: 1;
  }
 

 

  .slider-container .swiper {
    height: 400px;
  }  

.slider-container .swiper-slide{
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.slider-container .swiper-slide svg{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
 
  .cursor-pointer1{
 
  position: absolute;
   cursor: pointer;
  top:42%;

  left: 0px;
  font-size: 45px;
  color:  rebeccapurple;
  z-index: 1;
  width:100px;

}
.cursor-pointer1-product{ position: absolute;
  cursor: pointer;
 top:40%;

 left: 100px;
 font-size: 45px;
 color:  rebeccapurple;
 z-index: 1;
 width:100px;}
  .cursor-pointer1-artical{
    position: absolute;
    cursor: pointer;
   left:2%;

    z-index: 1;
  }
  .arrow-services-right{
    position: absolute;
    cursor: pointer;
   left:2%;
 
    z-index: 1;
  }
  .cursor-AddCard-1{
    position: absolute;
    cursor: pointer;
    left: 5%;
    top: 45%;
    z-index: 1;
  }
  .cursor-pointer1-products{
    position: absolute;
    cursor: pointer;
    left: -3%;
    top: 18%;
    z-index: 1;
  }
  .cursor-pointer-Scrollright{
    position: absolute;
    cursor: pointer;
   left:-8%;
    top:37%;
    z-index: 1;
  }

 
  .cursor-pointer2-artical{
    position: absolute;
  cursor: pointer;
  
 right:2%;

    z-index: 1;
  }
  .arrow-services-left{
    position: absolute;
  cursor: pointer;
  
 right:2%;
  
    z-index: 1;
  }
  .cursor-AddCard-2{
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 45%;
    z-index: 1;
  }
  .cursor-pointer2-products{
    position: absolute;
  cursor: pointer;
  
 
    top:18%;
    z-index: 1;
  }
  .cursor-pointer-Scrollleft{
    position: absolute;
  cursor: pointer;
  
 right: 3%;
    top:37%;
    z-index: 1;
  }
  .swiper{
    width: 100%;
    /* height: 100%; */
  height: 100% 1;
  }
}
@media(min-width:992px){
  .HoverCat .Allcat{
    width: 200px !important;
    height: 50px;

  }
  .Category .rts___tab{
    padding: 2px 9px;
  }
  .language{
    font-size: 0.8rem !important;
    /* color: #F52A59 !important; */
    color:#fff !important; 
    font-weight: 600 !important;
  }
  .navLinksDesktop a , span .ButtonServices {
    display: block;
    text-decoration: none;

    color:#fff; 
    font-size: 0.9rem;
  font-weight: 600;
  padding: 0px;
  
    transition: 0.5s ease;
    transform: translateX(0%);
  }

  .nav{
    justify-content: space-around;
     padding: 0 5vw;
     height: 60px;
 
   
 }

  [dir="rtl"].navLinks{
      position: absolute;
      left: 0%;
      top: 6vh;
      min-height: 92vh;
      background: #fff;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin: 0;
      padding: 0 2px;
      transform: translateX(-100%);
      transition: all 0.5s ease-in;
      justify-content:flex-start;
    
  }
  [dir="ltr"].navLinks{
    position: absolute;
    left: 1px;
    top: 6vh;
    min-height: 92vh;
   
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0;
    padding: 0 2px;
    transform: translateX(100%);
    transition: all 0.5s ease-in;

justify-content:flex-start;
  
}
.navLinks a:hover , a.ButtonServices:hover {
    color: #008C8D ;
  
  }
 
.rightnav{
  float: right;
  display: flex;
 width: 85%;
 align-items: center;
 justify-content: space-evenly;
 flex-direction: row;
}
[dir="ltr"] .rightnav{
  float: right;
  display: flex;
 width: 85%;
 align-items: center;
 justify-content: space-evenly;
 flex-direction: row;
}
.leftnav{
  float: left;
  display: flex;
 width: 40%;
 align-items: initial;

 flex-direction: row;
 gap: 1rem;
}
[dir="ltr"]  .leftnav{
  float: left;
  display: flex;
 width: 31%;
 align-items: initial;

 flex-direction: row;
}
  
}
@media only screen and  (min-width: 1024px) {
  .language{
    font-size: 0.8rem !important;
    /* color: #F52A59 !important; */
    color:#fff !important; 
    font-weight: 600 !important;
  }
  .navLinksDesktop a , span .ButtonServices {
    display: block;
    text-decoration: none;
    /* color:#F52A59; */
    color:#fff; 
    font-size: 0.8rem;
  font-weight: 600;
  
  padding: 0px !important;
    transition: 0.5s ease;
    transform: translateX(0%);
  }
 .nav{

   max-width: 969px;
 }
      .rightnav{

      display: flex;
      width: 42%;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;

      
    }
      [dir="ltr"] .rightnav{
        float: right;
        display: flex;
       width: 44%;
       align-items: center;
       justify-content: space-evenly;
       flex-direction: row;
    }
    .leftnav{
        float: left;
        display: flex;
       width: 48%;
       align-items: center;
       justify-content: left;
       flex-direction: row;
    }
    [dir="ltr"] .leftnav{
        float: left;
        display: flex;
       width: 48%;
       align-items: center;
       justify-content: right;
       flex-direction: row;
    }
} 
@media (min-width: 1200px)
{
  .language{
    font-size: 1rem !important;

    color:#fff !important; 
    font-weight: 600 !important;
  }
  .navLinksDesktop a , span .ButtonServices {
    display: block;
    text-decoration: none;

    color:#fff; 
    font-size: 1rem;
  font-weight: 600;
  
  
    transition: 0.5s ease;
    transform: translateX(0%);
  }
  .nav{
   height: 70px;
    
    max-width: 1120px;
  }
}
@media (min-width: 1241px)
{
  .language{
  font-size: 0.9rem !important;
}
  .navLinksDesktop a , span .ButtonServices{
    display: block;
    text-decoration: none;

    padding: 0px !important;
    color:#fff; 
    font-size: 0.9rem;
  font-weight: 600;
  
  
    transition: 0.5s ease;
    transform: translateX(0%);
  }
  .rightnav{
    float: right;
    display: flex;
   /* width: 87%; */
   width: 44%;
   align-items: center;
   justify-content: space-around;
   flex-direction: row;
}
  [dir="ltr"] .rightnav{
    float: right;
    display: flex;
   /* width: 80%; */
   width: 44%;
   align-items: center;
   justify-content: space-around;
   flex-direction: row;
}
.leftnav{
    float: left;
    display: flex;
   width: 53%;
   align-items: center;

   flex-direction: row;
   gap: 1rem;
   justify-content: left;
}
[dir="ltr"] .leftnav{
    float: left;
    display: flex;

   width: 53%;
   align-items: center;
   
   justify-content: right;
   flex-direction: row;
}
}
@media (min-width: 1517px)
{
  .language{
    font-size: 1rem !important;
  }
  .navLinksDesktop a , span .ButtonServices{
    display: block;
    text-decoration: none;
  
    color:#fff; 
    font-size: 1rem;
  font-weight: 600;
  
  
    transition: 0.5s ease;
    transform: translateX(0%);
  }
 
 
 
  .rightnav{
    float: right;
    display: flex;

   width: 44%;
   align-items: center;
   justify-content: space-around;
   flex-direction: row;
}
  [dir="ltr"] .rightnav{
    float: right;
    display: flex;
 
   width: 47%;
   align-items: center;
   justify-content: space-around;
   flex-direction: row;
}
.leftnav{
    float: left;
    display: flex;
   width: 53%;
   align-items: center;
  
   flex-direction: row;
   gap: 1rem;
   justify-content: left;
}
[dir="ltr"] .leftnav{
    float: left;
    display: flex;
 
   width: 53%;
   align-items: center;

   flex-direction: row;
}
}

.pl-10{
  padding-inline-start: 10px;
}
.pr-10{
  padding-inline-end: 10px;
}
.pt-10{
  padding-top: 10px;
}
.pb-10{
  padding-bottom: 10px;
}
.flex{
  display: flex;
}
.text-center{
  text-align: center;
}
.justify-center{
  justify-content: center;
}
.align-center{
  align-items: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.mr-auto{
margin-right: auto;
}
.ml-auto{
  margin-left: auto;
}
.mt-10{
margin-top: 10px;
  }
  .mb-10{
    margin-bottom: 10px;
  }
  .justify-end{
    justify-content: end;
  }
  .grid-template-columns-2{
    grid-template-columns: repeat(2,1fr) ;
}
.pac-container{
  z-index:10000000;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
#__next{
  height: 100%;
}
.rts___tabs___container{
  overflow: unset !important;
}